import { DealStages, DealStagesStep } from "@coxauto-ui/ccxm/interfaces";
import { MinusCircleIcon } from "@interstate/components/Icons";
import { CheckCircleIcon } from "@interstate/components/Icons/CheckCircleIcon";

export enum AllowedField {
  status = "status",
  date = "date"
}

const noCompleteColor = "#B2B2B2";

const completeColor = "#3c7b29";

const noCompleteStage = {
  color: noCompleteColor,
  icon: <MinusCircleIcon />
};

const completeStage = {
  color: completeColor,
  icon: <CheckCircleIcon />
};

const baseSteps = [
  {
    key: "negotiation",
    label: "Negotiation"
  },
  {
    key: "checkout",
    label: "Checkout"
  },
  {
    key: "contracting",
    label: "Contracting"
  },
  {
    key: "delivery",
    label: "Delivery"
  }
];

export function mapDealChecker(
  dealStages: DealStages,
  allowedFields: AllowedField[]
) {
  const getSubtext = (stage: DealStagesStep) => {
    const subtext = [];
    if (allowedFields.includes(AllowedField.status)) {
      subtext.push(stage.state);
    }
    if (allowedFields.includes(AllowedField.date) && stage.completionDateUtc) {
      subtext.push(new Date(stage.completionDateUtc).toLocaleString());
    }
    return subtext;
  };

  return baseSteps.map(({ key, label }, index) => {
    const stageKey = key as keyof DealStages;
    const nextStageKey = baseSteps[index + 1]?.key as keyof DealStages;
    const stage = dealStages[stageKey] as DealStagesStep;
    const nextStage = dealStages[nextStageKey] as DealStagesStep;
    const nextStageIsComplete = nextStage?.isComplete;
    const nextColor = nextStageIsComplete ? completeColor : noCompleteColor;
    return {
      label,
      nextColor,
      subtext: getSubtext(stage),
      ...(stage.isComplete ? completeStage : noCompleteStage)
    };
  });
}
