import styled from "styled-components";
import ProgressCheckerStep from "./progress-checker-step";

/* eslint-disable-next-line */
export interface ProgressCheckerProps {
  className: string;
  ["data-testid"]: string;
  id: string;
  steps: Step[];
}

export interface Step {
  color: string;
  icon: React.ReactNode;
  label: string;
  subtext?: string[];
}

const StyledProgressChecker = styled.div`
  display: flex;
`;

export function ProgressChecker({
  className,
  id,
  steps,
  ...other
}: ProgressCheckerProps) {
  return (
    <StyledProgressChecker
      className={className}
      data-testid={other["data-testid"]}
      id={id}
    >
      {steps.map(({ color, icon, label, subtext }, i) => (
        <ProgressCheckerStep
          color={color}
          data-testid={`${other["data-testid"]}-${i}`}
          icon={icon}
          id={`${id}-${i}`}
          key={label}
          label={label}
          nextColor={i === steps.length - 1 ? color : steps[i + 1].color}
          position={
            i === 0 ? "first" : i === steps.length - 1 ? "last" : "middle"
          }
          subtext={subtext}
        />
      ))}
    </StyledProgressChecker>
  );
}

export default ProgressChecker;
