import styled from "styled-components";

export const StyledProgressCheckerStep = styled.div`
  height: min-content;
  min-width: 0;
  flex-grow: 2;
  flex-basis: 0;

  .stepper-visual-indicator {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;

    hr {
      border: none;
      border-top: 1px solid;
      height: 0;
      margin: 7px 0;
    }

    .stepper-horizontal-left {
      grid-area: 1/1/2/2;
    }

    .stepper-horizontal-right {
      grid-area: 1/2/2/3;
    }

    .stepper-icon-wrapper {
      grid-area: 1/1/2/3;
      display: flex;
      justify-content: center;

      .stepper-icon {
        position: relative;
        display: inline-block;
        height: 16px;
        margin: 0 6px;
        background-color: transparent;

        svg {
          background-color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  .stepper-text-wrapper {
    text-align: center;

    .stepper-label {
      overflow: hidden;
      text-overflow: ellipsis;
      padding-top: 4px;
    }

    .stepper-subtext {
      font-size: 12px;
      line-height: 16px;
      color: #6d6d6d;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.first {
    justify-content: left;
    flex-grow: 1;

    .stepper-visual-indicator .stepper-icon-wrapper {
      justify-content: left;
    }

    .stepper-text-wrapper {
      text-align: left;
    }

    .stepper-visual-indicator .stepper-horizontal-right {
      grid-area: 1/1/2/3;
    }
  }

  &.last {
    justify-content: right;
    flex-grow: 1;

    .stepper-visual-indicator .stepper-icon-wrapper {
      justify-content: right;
    }

    .stepper-text-wrapper {
      text-align: right;
    }

    .stepper-visual-indicator .stepper-horizontal-left {
      grid-area: 1/1/2/3;
    }
  }
`;
